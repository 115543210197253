import { ImageWrap } from '@truphone/gatsby-wrappers'
import React, { useState } from 'react'

function TitledIcon(props) {
  return (
    <div className="mx-8 lg:mx-0 mb-4 w-full max-w-lg p-4 border-2 border-grey-100">
      <div className="flex justify-between" onClick={props.toggleSelected}>
        <h3 className="h5-light">{props.title}</h3>
        <span className="text-white text-2xl cursor-pointer"> {props.selected ? '-' : '+'} </span>
      </div>
      {props.selected && (
        <div className="lg:ml-12 my-6">
          {props.icons?.map((x, i) => (
            <div key={`icon-${i}`} className="flex mb-6">
              <ImageWrap className="w-[40px] h-[40px]" {...x.icon} />
              <div className="flex flex-col ml-6">
                <h3 className="h6-light">{x.title}</h3>
                <p className="text-sm opacity-70">{x.text}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

function Icon(props) {
  return (
    <>
      <div className="w-full flex items-center my-4">
        <ImageWrap className="w-[70px] h-[80px]" {...props.icon} />
        <h3 className="h5-light ml-8">{props.title}</h3>
      </div>
      <hr className="w-full my-8" />
    </>
  )
}

function ImageIconGroup(props) {
  const [selected, setSelected] = useState(0)
  return (
    <div className="py-10 lg:py-20 relative">
      {props.backgroundImage && (
        <div className="block w-full h-full absolute left-0 top-0 bg-cover bg-center overflow-hidden">
          <ImageWrap className="block w-full h-full object-cover" {...props.backgroundImage} />
        </div>
      )}
      <div
        className={`flex flex-col relative ${
          props.imageOnRight ? 'lg:flex-row-reverse' : 'lg:flex-row '
        } container justify-center items-center mx-auto`}
        style={{
          color: props.textColor?.hex
        }}
      >
        <div className="lg:w-1/2">
          <ImageWrap objectFit="contain" className="mx-4 pb-10" {...props.image} />
        </div>
        <div
          className={`lg:w-1/2 ${
            props.imageOnRight ? 'lg:mr-32' : 'lg:ml-32'
          } flex flex-col justify-center mt-4 md:mt-0 px-4`}
        >
          {props.title && <h3 className="h3-light mb-6">{props.title}</h3>}
          {props.content && (
            <div
              className="h5-light mb-6"
              dangerouslySetInnerHTML={{ __html: props.content }}
            ></div>
          )}
          <div className="flex flex-col items-center md:items-start">
            {props.icons?.map((x, i) => (
              <>
                {x.icons && x.icons.length > 0 ? (
                  <TitledIcon
                    selected={selected === i}
                    toggleSelected={() => (selected === i ? setSelected(null) : setSelected(i))}
                    {...x}
                  />
                ) : (
                  <Icon {...x} />
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageIconGroup
