import React, { useState } from 'react'
import { ImageWrap } from '@truphone/gatsby-wrappers'
import { uppercaseString } from '@truphone/utils'
import { SecondaryButton } from '@truphone/buttons'

const Underline = ({ className }) => (
  <svg
    className={className}
    width="127"
    height="5"
    viewBox="0 0 127 5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 3.103c52.3-2 93.739-2.5 124.314-1.5"
      stroke="#ADF22E"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const PricingColumn = (props) => {
  const [selectedOption, setSelectedOption] = useState(-1)

  return (
    <div className="md:grid w-full mt-4 px-4 md:w-1/2 mr-6" style={{ gridTemplateRows: '1fr 4fr' }}>
      <div>
        <span className="text-4xl text-green-100 font-light leading-5 tracking-tight">
          {props.number}.
        </span>
        <h3 className="text-3xl-custom font-medium">
          <span className="inline-block relative">
            {props.highlightText}
            <Underline className="block absolute bottom-1" />
          </span>
          {props.title}
        </h3>
        <p className="text-base">{props.content}</p>
      </div>
      <div className="md:grid" style={{ gridTemplateRows: '5fr 1fr 5fr' }}>
        {props.options?.map((option, index, list) => (
          <PricingOption
            {...option}
            key={`option-${index}`}
            optionSeparatorText={props.optionSeparatorText}
            separator={index < list.length - 1}
            selected={selectedOption === index}
            showSelectedContent={props.showSelectedContent}
            showCta={selectedOption === index && props.showCta}
            onClick={() => {
              setSelectedOption(index)
              if (props.onSelected && typeof props.onSelected === 'function') {
                props.onSelected(index)
              }
            }}
          />
        ))}
      </div>
    </div>
  )
}

const PricingOption = (props) => {
  return (
    <>
      <div
        className={`rounded-sm border-l-4 ${
          props.selected
            ? 'border-gray-50 cursor-default bg-blue-300 text-white'
            : 'border-green-100 hover:border-blue-300 cursor-pointer bg-white hover:bg-gray-50 text-black'
        } flex flex-col shadow-md px-4 py-6 mr-4 my-6  `}
        onClick={props.onClick}
      >
        {props.selected ? (
          <ImageWrap className="w-6 h-6 mb-2" {...props.selectedIcon} />
        ) : (
          <ImageWrap className="w-6 h-6 mb-2" {...props.icon} />
        )}

        <h4 className="mb-2 text-xl font-light">{props.title}</h4>
        {props.showCta && props.selectedCta?.length > 0 ? (
          <SecondaryButton
            className="self-start"
            href={props.selectedCta[0].href}
            targer={props.selectedCta[0].openTab ? '_blank' : ''}
          >
            {props.selectedCta[0].text}
          </SecondaryButton>
        ) : (
          <>
            {props.selected ? (
              <>
                {props.showSelectedContent && (
                  <div className="text-base text-white mb-6">{props.selectedContent}</div>
                )}
              </>
            ) : (
              <div className="body1-muted mb-6">{props.content}</div>
            )}
          </>
        )}
      </div>

      {props.separator && (
        <div className="flex items-center">
          <hr className="grow" />
          <span className="overline-text mx-2">{uppercaseString(props.optionSeparatorText)}</span>
          <hr className="grow" />
        </div>
      )}
    </>
  )
}

function PricingModelBlock(props) {
  const [selectedColumns, setSelectedColumns] = useState([])
  const [lastSelectedColumn, setLastSelectedColumn] = useState(-1)

  return (
    <div className="relative py-20">
      <div className="block w-full h-full absolute left-0 top-0 bg-cover bg-center overflow-hidden">
        <ImageWrap className="block w-full h-full object-cover" {...props.backgroundImage} />
      </div>
      <div className="relative container mx-auto text-white ">
        <div className="flex flex-col md:flex-row">
          {props.columns?.map((x, i) => (
            <PricingColumn
              {...x}
              key={`column-${i}`}
              number={i + 1}
              optionSeparatorText={props.optionSeparatorText}
              showSelectedContent={selectedColumns.length !== props.columns.length}
              showCta={selectedColumns.length === props.columns.length && lastSelectedColumn === i}
              onSelected={() => {
                if (selectedColumns.indexOf(i) === -1) {
                  selectedColumns.push(i)
                  setSelectedColumns(selectedColumns)
                }
                setLastSelectedColumn(i)
              }}
            />
          ))}
        </div>
        {props.footerText && (
          <div className="mx-16 text-center mt-4">
            <p className="text-3xl-custom font-medium">{props.footerText}</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default PricingModelBlock
