import { ArrowButton } from '@truphone/buttons'
import { ArrowSimple } from '@truphone/icons'
import React, { useRef } from 'react'

export default function PagedTabContainer(props) {
  const container = useRef([])

  function moveFeatures(number) {
    container.current[number].scrollIntoView()
  }
  return (
    <div className="bg-blue-900">
      <div className="container mx-auto px-4 xl:px-0 py-4 lg:py-10">
        {props.tabs &&
          props.tabs.map((tab, i) => {
            return (
              <div
                key={`tab-content-${i}`}
                className={`text-white relative`}
                style={{ scrollMarginTop: '150px' }}
                id={tab.fragment || `sticky-tab-${i}`}
                ref={(el) => (container.current[i] = el)}
              >
                <div
                  className={`rounded-2xl bg-green-100 text-blue-900 px-4 py-3 mb-4 sticky z-1 flex justify-between`}
                  style={{ top: '158px' }}
                >
                  <div>
                    {tab.topTitle && (
                      <p className="text-xs lg:text-base font-medium uppercase tracking-widest mb-2">
                        {tab.topTitle}
                      </p>
                    )}
                    {tab.title && (
                      <div className="flex items-center">
                        {tab.icon && <img src={tab.icon.url} className="mr-4 block" />}
                        <h3 className="text-2xl lg:text-4xl leading-tight">{tab.title}</h3>
                      </div>
                    )}
                  </div>
                  <div className="ml-auto hidden lg:flex">
                    <div
                      onClick={() => i !== 0 && moveFeatures(i - 1)}
                      className={`border-2 ${
                        i === 0 ? 'opacity-30' : 'cursor-pointer opacity-100'
                      } border-blue-700 rounded-full h-11 w-11 flex justify-center items-center m-3`}
                    >
                      <ArrowSimple className="w-4 h-4 rotate-90"></ArrowSimple>
                    </div>
                    <div
                      onClick={() => i !== props.tabs.length - 1 && moveFeatures(i + 1)}
                      className={`border-2 ${
                        i === props.tabs.length - 1 ? 'opacity-30' : 'cursor-pointer opacity-100'
                      } border-blue-300 rounded-full h-11 w-11 flex justify-center items-center m-3`}
                    >
                      <ArrowSimple className="w-4 h-4 -rotate-90"></ArrowSimple>
                    </div>
                  </div>
                </div>

                {tab.content &&
                  tab.content.length > 0 &&
                  tab.content.map((tabContent, i, list) => {
                    return (
                      <div
                        className={`py-4 lg:py-16 flex flex-col lg:flex-row justify-between ${
                          i < list.length - 1 ? 'border-b border-blue-300' : ''
                        }`}
                        key={`tab-content-${i}`}
                      >
                        <div className="lg:max-w-[278px] mb-16 lg:mb-0">
                          {tabContent.icon && <img src={tabContent.icon.url} />}
                          {tabContent.title && (
                            <div
                              className="text-2xl lg:text-3xl-custom font-medium mt-4 green-b-tag"
                              dangerouslySetInnerHTML={{ __html: tabContent.title }}
                            />
                          )}
                          {tabContent.text && (
                            <div
                              className="text-base green-b-tag mt-4"
                              dangerouslySetInnerHTML={{ __html: tabContent.text }}
                            />
                          )}
                        </div>
                        <div className="lg:w-1/2">
                          {tabContent.features &&
                            tabContent.features.length > 0 &&
                            tabContent.features.map((feature, i) => {
                              return (
                                <div
                                  className={`pb-10 ${
                                    i === 0 ? '' : 'pt-10 border-t border-white border-opacity-30'
                                  }`}
                                  key={`tab-feature-${i}`}
                                >
                                  {feature.title && <h4 className="text-xl">{feature.title}</h4>}
                                  {feature.text && (
                                    <div className="text-sm mt-4">{feature.text}</div>
                                  )}
                                  {feature.cta && (
                                    <ArrowButton
                                      href={feature.cta.href}
                                      className="text-xl mt-4 text-green-100"
                                    >
                                      {feature.cta.text}
                                    </ArrowButton>
                                  )}
                                </div>
                              )
                            })}
                        </div>
                      </div>
                    )
                  })}
              </div>
            )
          })}
      </div>
    </div>
  )
}
